import {
  Box,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import sg, { GET_INDEXPAGE } from '../../serviceGlobal';

const createData = (
  idx,
  size,
  kind,
  one,
  two,
  memo
) => ({
  idx,
  size,
  kind,
  one,
  two,
  memo
});

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));
const Workshop = () => {
  const classes = useStyles();
  const [imgLst, setImgLst] = useState([]);
  const ref = useRef(null);
  const client = useApolloClient();
  sg.mute(createData);
  useEffect(() => {
    client.query({ query: GET_INDEXPAGE, variables: { pageId: 277 } }).then((retObj) => {
      if (retObj.data?.page) {
        const { content } = retObj.data.page;
        const imgArray = content.match(/<img.*?src=.*?["'](.*?)["'].*?\/>/ig);
        const lst = [];
        if (imgArray) {
          imgArray.forEach((element, index) => {
            const objMatch = element.match(/<img.*?src=.*?["'](.*?)["'].*?\/>/);
            const [whole, src] = objMatch;
            const srcVal = src.replace('http:', sg.httpVal);
            lst.push({
              idx: index + 1,
              src: srcVal,
            });
            sg.mute(whole);
          });
        }
        setImgLst(lst);
      }
    });
    /*
    const paramObj = {
      json: 'get_page',
      id: 277,
      post_type: 'page'
    };
    sg.get(sg.apiUrl, paramObj).subscribe((retObj) => {
      if (retObj.page) {
        const { content } = retObj.page;
        const imgArray = content.match(/<img.*?src=.*?["'](.*?)["'].*?\/>/ig);
        const lst = [];
        if (imgArray) {
          imgArray.forEach((element, index) => {
            const objMatch = element.match(/<img.*?src=.*?["'](.*?)["'].*?\/>/);
            const [whole, src] = objMatch;
            const srcVal = src.replace('http:', sg.httpVal);
            lst.push({
              idx: index + 1,
              src: srcVal,
            });
            sg.mute(whole);
          });
        }
        setImgLst(lst);
      }
    });
    */
  }, []);
  return (
    <Box
      ref={ref}
      className={classes.root}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start'
      }}
    >
      <Box
        sx={{
          background: 'rgba(0,0,0, 0.1)',
          border: '1px solid rgba(255,255,255,0.8)',
          flex: '0 1 auto',
          textAlign: 'center',
          mb: 1,
          padding: 2
        }}
      >
        <Typography
          variant="h4"
        >
          Yoyo&apos;s workshop
        </Typography>
      </Box>
      {imgLst[0] && (
        <Box
          sx={{
            mb: 2,
            display: 'flex',
            flexWrap: 'wrap'
          }}
        >
          <Box
            sx={{
              mr: '5px'
            }}
          >
            <img
              src={imgLst[0].src}
              alt=""
              style={{
                width: '300px'
              }}
            />
          </Box>
          <Box
            sx={{
              flex: '1 0 100px'
            }}
          >
            <Typography
              sx={{
                mb: 2
              }}
            >
              Heb je nog nooit een portret geschilderd en zou je dat eigenlijk heel graag willen leren? En zou het je geweldig lijken om portretten van je eigen kinderen of kleinkinderen te kunnen schilderen ? Of van je huisdieren . Misschien wil je wel een schilderij van jezelf aan de muur hangen.
            </Typography>
            <Typography
              sx={{
                mb: 2
              }}
            >
              Je zou individueel begeleid worden in de les.
            </Typography>
            <Typography
              sx={{
                mb: 2
              }}
            >
              Deze cursus is zowel voor beginners als gevorderden.
            </Typography>
            <Typography
              sx={{
                mb: 2
              }}
            >
              Iedere cursist neemt zijn/haar eigen materialen mee.
            </Typography>
            <Typography
              sx={{
                mb: 2
              }}
            >
              Materiaal: potlood, acryl, olieverf… (zelf bepalen)
            </Typography>
            <Typography sx={{ fontWeight: 'bold' }}>
              Locatie: Salemate 78
            </Typography>
            <Typography
              sx={{
                ml: 2
              }}
            >
              Aantal lessen: 10 (Kleine groep van 1-4) Kosten: €300
            </Typography>
            <Typography
              sx={{
                ml: 2
              }}
            >
              Tijd: 2 uur 13:30-15:30 maandag
              <br />
              Tijd: 2 uur 18:30-20:30 dinsdag
              <br />
              Tijd: 2 uur 09:30-11:30 woensdag
              <br />
              Tijd: 2 uur 10:30-12:30 zondag
            </Typography>
            <Typography sx={{ fontWeight: 'bold' }}>
              Locatie: Hofstraat 2, Doetinchem schilderlokaal 3.1
            </Typography>
            <Typography
              sx={{
                ml: 2
              }}
            >
              Aantal lessen: 10 (groep van 4-15)
            </Typography>
            <Typography
              sx={{
                ml: 2
              }}
            >
              Kosten : €150, 10 lessen, 2 uur, woensdag: 16:00-18:00
              <br />
              Kosten : €100, 10 lessen(meedoen), 2 uur, donderdag: 19:30-21:30(eerst uur met live model)
            </Typography>
            <Typography sx={{ fontWeight: 'bold' }}>
              Locatie: Hofstraat 11A, 7011 AA Gaanderen
            </Typography>
            <Typography
              sx={{
                ml: 2
              }}
            >
              Aantal lessen: 10 (groep van 4-15)
              <br />
              Kosten : €150, 10 lessen, 2 uur, woensdag: 13:00-15:00(om de week)
            </Typography>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          background: 'rgba(0,0,0, 0.1)',
          border: '1px solid rgba(255,255,255,0.8)',
          flex: '0 1 auto',
          textAlign: 'center',
          mb: 2,
          padding: 2
        }}
      >
        <Typography
          variant="h4"
        >
          Students&apos;s works
        </Typography>
      </Box>
      <Box>
        <Grid
          container
          spacing={3}
          alignItems="strecth"
        >
          {imgLst.slice(1).map((img) => (
            <Grid
              item
              key={img.idx}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              xl={3}
            >
              <Box
                sx={{
                  height: '100%',
                  width: '100%',
                  position: 'relative',
                  display: 'block',
                }}
              >
                <Box
                  className="picFrame"
                >
                  <img
                    src={img.src}
                    alt=""
                    style={{
                      width: '100%'
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Workshop;
